import React, { useCallback, useState } from 'react';
import { ErrorMessage, Formik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { createPitch } from "../api";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import emailIcon from "../assets/icon.svg"
import { useDropzone } from 'react-dropzone';
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png"

// import Select from 'react-select'




const AddPitch = ({ setIsOpen, isOpen, queryId, refetch }) => {

    // console.log(pdflevel, "pdflevel")



    let storedObject = localStorage.getItem('user');

    let firstname = '';
    let lastname = '';
    let jobtitle = '';
    let userEmail = '';


    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject.first_name;
        lastname = parsedObject.last_name;
        jobtitle = parsedObject.job_title;
        userEmail = parsedObject.email;
    } else {
    }

    const acceptedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const [fileExtensions, setFileExtensions] = useState
        ([]);




    const signInSchema = yup.object().shape({
        title: yup.string().required("Subject is required"),
        // media_outlet_url: yup.string().required("Fullname is required"),
        // brief: yup.string().required("Fullname is required"),
        // description: yup.string().required("Fullname is required"),
        // date: yup.string().required("Fullname is required"),
    });


    const { mutate, isLoading } = useMutation(createPitch, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setIsOpen(false)
            refetch()
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });

    const onDrop = useCallback((acceptedFiles) => {
        // Store the file extensions
        const extensions = acceptedFiles.map(file => file.name.split('.').pop().toLowerCase());
        setFileExtensions(extensions);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles,
    } = useDropzone({
        onDrop,
        accept: acceptedFileTypes.join(','),
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const uploadedFiles = acceptedFiles.map((file, index) => (
        file
    ));
    console.log("uploadedFiles", uploadedFiles, "uploadedFiles")


    const initialValues =
    {
        fullname: `${firstname} ${lastname}`,
        jobtitle: jobtitle,
        userEmail: userEmail,
        title: '',
        description: '',
        is_anonymous: "false",
        // attachments: uploadedFiles, 
    };





    return (
        <div className="container">
            <Formik
                initialValues={initialValues}
                validationSchema={signInSchema}
                onSubmit={(values) => mutate({ ...values, queryId, attachments: uploadedFiles })}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="query-label">Full Name *</label>
                                <input type="text"
                                    className="form-control query-modal-div"
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Job Title *</label>
                                <input type="text"
                                    className="form-control query-modal-div"
                                    name="job_title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.jobtitle}
                                    disabled
                                ></input>
                            </div>



                            <div className="col-lg-12">
                                <label className="query-label">Email</label>
                                <div className="query-modal-div">
                                    <input type="email" className="form-control my-inputing"
                                        name="userEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userEmail}
                                        disabled
                                    ></input>
                                    <img src={emailIcon} alt="" />
                                </div>
                                {/* <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="signup-label" for="exampleCheck1">Post Anonymously</label>
                                </div> */}
                            </div>


                            <div className="col-lg-12">
                                <label className="query-label">Subject *</label>
                                <input type="text" className="form-control query-input"
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                ></input>
                                <ErrorMessage name="title" component="div" className="text-red-500 text-[1rem]" />
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Post Anonymously *</label>
                                <select
                                    className="form-control query-input"
                                    name="is_anonymous"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.is_anonymous}
                                >
                                    <option value="">Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                <ErrorMessage name="is_anonymous" component="div" className="text-red-500 text-[1rem]" />
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Description *</label>
                                <textarea type="text" className="form-control query-input1"
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                ></textarea>
                                <ErrorMessage name="description" component="div" className="text-red-500 text-[1rem]" />
                            </div>
                            <div className='col-lg-12 mt-5 '>
                                <div className='source-upload-file'>
                                    <div {...getRootProps()} style={dropzoneStyles}>
                                        <input {...getInputProps()} />
                                        <p>Drop your files or click to upload</p>
                                        <p>Supported file types: PNG, JPEG JPG, PDF</p>
                                    </div>
                                </div>
                                {/* <ul>{files}</ul> */}
                                {acceptedFiles.map((file, index) => (
                                    <div key={index}>
                                        <div className='d-flex'>
                                            {fileExtensions[index] === "pdf" && (
                                                <div>
                                                    <img src={pdflevel} alt="PDF" />
                                                </div>
                                            )}
                                            {fileExtensions[index] === "jpeg" && (
                                                <div>
                                                    <img src={jpeglevel} alt="JPEG" />
                                                </div>
                                            )}
                                            {fileExtensions[index] === "jpg" && (
                                                <div>
                                                    <img src={blackjpg} alt="JPG" />
                                                </div>
                                            )}
                                            {fileExtensions[index] === "png" && (
                                                <div>
                                                    <img src={bluepng} alt="PNG" />
                                                </div>
                                            )}
                                            <div>
                                                <p className='file-name-kb'>{file.name}</p>
                                                <p className='file-size-kb'>{file.size} KB</p>
                                            </div>
                                        </div>
                                    </div>

                                ))}


                            </div>
                        </div>
                        <div className="create-query-div">
                            <button type="submit" disabled={isLoading} className="create-query-btn">Submit</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
const dropzoneStyles = {
    // border: '2px dashed #cccccc',
    // borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};
export default AddPitch;
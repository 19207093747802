import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import MainNavbar from "../nav/MainNavbar";
import { getNotification, readNotification } from "../api";
import { Link } from "react-router-dom";
import { parseISO, formatDistanceToNow } from 'date-fns';
import ReactPaginate from "react-paginate";
import { renderErrorMessage } from "../assets/functions";
import { Helmet } from "react-helmet";

const Notification = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { data } = useQuery({
        queryKey: ["getNotification", currentPage],
        queryFn: () => getNotification(currentPage),
        suspense: true,
    });

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const resultsPerPage = 10;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'yesterday';
        } else {
            return 'older';
        }
    };

    const todayNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'today'
    );

    const yesterdayNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'yesterday'
    );

    const olderNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'older'
    );

    // Combine all notifications
    const allNotifications = [
        ...(todayNotifications || []),
        ...(yesterdayNotifications || []),
        ...(olderNotifications || []),
    ];

    const { mutate } = useMutation(readNotification, {
        onSuccess: (data) => {
            // Handle success
        },
        onError: (error) => renderErrorMessage(),
    });

    return (
        <div>
            <Helmet>
                <title>Connected Newsroom | Notofication</title>
            </Helmet>
            <MainNavbar />
            <div className="container">
                <p className="my-queries-sub">Notifications</p>
                <div className="query-details-container">
                    {/* Check if there are no notifications */}
                    {allNotifications.length === 0 ? (
                        <div className="no-notifications-message">
                            <h2>No Notifications</h2>
                        </div>
                    ) : (
                        <div>
                            {todayNotifications?.length > 0 && (
                                <div>
                                    <h2 className="notify-past">Today</h2>
                                    {todayNotifications?.map((notification, index) => (
                                        <div className={notification.is_read ? "learn-more-container1" : "learn-more-container"} key={index}>
                                            <div>
                                                <h3 className="learn-more-text">{notification?.message}</h3>
                                                <h3 className="learn-more-text33 mt-2">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                            </div>
                                            <Link to={`/queries/${notification?.query_uuid}`}>
                                                <p className="learn-more-link" onClick={() => mutate(notification?.uuid)}>View</p>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {yesterdayNotifications?.length > 0 && (
                                <div>
                                    <h2 className="notify-past mt-5">Yesterday</h2>
                                    {yesterdayNotifications?.map((notification, index) => (
                                        <div className={notification.is_read ? "learn-more-container1 mb-3" : "learn-more-container mb-3"} key={index}>
                                            <div>
                                                <h3 className="learn-more-text">{notification?.message}</h3>
                                                <h3 className="learn-more-text33 mt-2">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                            </div>
                                            <Link to={`/queries/${notification?.query_uuid}`}>
                                                <p className="learn-more-link" onClick={() => mutate(notification?.uuid)}>View</p>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {olderNotifications?.length > 0 && (
                                <div>
                                    <h2 className="notify-past mt-5">Older Notifications</h2>
                                    {olderNotifications?.map((notification, index) => (
                                        <div className={notification.is_read ? "learn-more-container1 mb-3" : "learn-more-container mb-3"} key={index}>
                                            <div>
                                                <h3 className="learn-more-text">{notification?.message}</h3>
                                                <h3 className="learn-more-text33 mt-1">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                            </div>
                                            <Link to={`/queries/${notification?.query_uuid}`}>
                                                <p className="learn-more-link" onClick={() => mutate(notification?.uuid)}>View</p>
                                            </Link>
                                        </div>
                                    ))}
                                    <ReactPaginate
                                        previousLabel={'< Prev'}
                                        previousClassName="previous-class"
                                        nextLabel={'Next >'}
                                        nextClassName="next-class"
                                        pageCount={Math.ceil(data?.data?.total / resultsPerPage)}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active-paginate'}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Notification;

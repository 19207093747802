
import React, { useState, useEffect } from 'react';
import { ErrorMessage, Formik } from "formik";
import Select from 'react-select'
import FileUpload from '../components/FileUpload';
import DocumentDropzone from '../components/DocumentDropzone';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CreateLeak, EditALeak, getAllJournalist, getAssociatedOutlets, getTags } from '../api';
import { renderErrorMessage, renderSuccessMessage } from '../assets/functions';
import EditLeakDropzone from '../components/EditLeakDropzone';
// import Select from 'react-select'



const AddLeak = ({ setIsOpen, myownleak }) => {
    // console.log(myownleak?.uuid, "myownleak")
    const [selectedFiles, setSelectedFiles] = useState([]);

    // Callback function to handle the files passed from EditLeakDropzone
    const handleFilesChangeEdit = (files) => {
        // console.log("Files received from EditLeakDropzone:", files);
        setSelectedFiles(files);
    };


    const initialValues = myownleak ?
        {
            title: myownleak?.title,
            tags: myownleak?.tags?.map(tag => ({ label: tag, value: tag })) || [],

            outlets: myownleak?.out?.map(out => ({ label: out, value: out })) || [],
            // attachments: [],
            description: myownleak?.description,
            // is_anonymous: "false",
            id: myownleak?.uuid,
            attachments: [],
        }
        :
        {
            title: '',
            tags: [],
            outlets: [],
            audience: [],
            attachments: [],
            description: '',
            is_anonymous: "false",
        };


    // const initialValues = {
    //     title: '',
    //     tags: [],
    //     outlets: [],
    //     audience: [],
    //     attachments: [],
    //     description: '',
    //     is_anonymous: "false",
    // }


    const [searchQuery, setSearchQuery] = useState('');
    const [selectedJournalists, setSelectedJournalists] = useState([]);

    const { data: gettags } = useQuery({
        queryKey: ["getTags"],
        queryFn: () => getTags(),
    });
    const { data: outlet } = useQuery({
        queryKey: ["getAssociatedOutlets"],
        queryFn: () => getAssociatedOutlets(),
    });
    // Fetch journalists based on the search query
    const { data: journa, refetch } = useQuery({
        queryKey: ['getAllJournalist', searchQuery],
        queryFn: () => getAllJournalist(searchQuery),
        enabled: !!searchQuery, // Prevents the query from running on initial render
    });




    const options1 = outlet?.data.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));
    const options2 = gettags?.data.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));

    // const options = journa?.data?.data.map((option, index) => ({
    //     value: option?.first_name,
    //     label: option?.first_name,
    // })) || [];
    // console.log("options", journa?.data?.data)
    const journalistOptions = journa?.data?.data.map((option) => ({
        value: option?.uuid, // Using uuid as the value
        label: option?.first_name, // Using first_name as the label
    })) || [];


    // Handle search and fetch results as the user types
    useEffect(() => {
        if (searchQuery) {
            refetch(); // Trigger the API call when searchQuery changes
        }
    }, [searchQuery, refetch]);

    // Handle selection of journalists
    const handleSelectChange = (selectedOptions) => {
        setSelectedJournalists(selectedOptions);
    };

    const [selectedDropdown, setSelectedDropdown] = useState(
        myownleak?.audience?.length ? 'journalist' :
            myownleak?.outlets?.length ? 'media-outlet' :
                'category'
    );
    // State to manage selected options for each dropdown
    // const [selectedJournalist, setSelectedJournalist] = useState([]);
    const [selectedMediaOutlet, setSelectedMediaOutlet] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const controlOptions = [
        { value: 'journalist', label: 'Journalist' },
        { value: 'media-outlet', label: 'Media Outlet' },
        { value: 'category', label: 'Category' },
    ];
    const defaultOption = selectedDropdown === 'journalist'
        ? controlOptions[0]
        : selectedDropdown === 'media-outlet'
            ? controlOptions[1]
            : controlOptions[2];


    const handleControlChange = (selectedOption) => {
        setSelectedDropdown(selectedOption.value);
        // console.log(selectedOption.value);
    };


    const { mutate, isLoading } = useMutation(myownleak ? EditALeak : CreateLeak, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message)
            // .then(() => refetch());
            setIsOpen(false)
            // refetch()
            // window.location.reload();
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });



    const [imageFiles, setImageFiles] = useState([]);
    const [documentFiles, setDocumentFiles] = useState([]);

    const handleFilesChange = (type, files) => {
        if (type === 'images') {
            setImageFiles(files);
        } else if (type === 'documents') {
            setDocumentFiles(files);
        }
    };




    return (
        <div className="container">
            <Formik
                initialValues={initialValues}

                onSubmit={(values, { setSubmitting }) => {
                    // Combine imageFiles and documentFiles into new attachments
                    const combinedFiles = [...imageFiles, ...documentFiles];

                    // Transform other fields as needed
                    const transformedTags = values.tags.map(tag => tag.value);
                    const transformedOutlets = values.outlets.map(out => out.value);
                    const transformedAudience = selectedJournalists.map(journalist => `@${journalist.value}`);

                    // Prepare the data for submission
                    const data = {
                        ...values,
                        tags: transformedTags,
                        outlets: transformedOutlets,
                        audience: transformedAudience,
                    };

                    // Check if we are editing or creating
                    if (myownleak) {
                        // Editing an existing leak
                        const existingAttachments = myownleak.attachments || [];
                        const allAttachments = [
                            ...existingAttachments,
                            ...selectedFiles.map(fileObj => fileObj.file)
                        ];
                        // console.log("yqsusuiss", existingAttachments, selectedFiles)


                        const editData = {
                            ...data,
                            id: myownleak.uuid, // Add the id for editing
                            attachments: allAttachments, // Combine existing and new attachments
                        };

                        mutate(editData);  // Use EditALeak for editing
                        // console.log("editData", editData)

                    } else {
                        // Creating a new leak
                        const createData = {
                            ...data,
                            attachments: combinedFiles, // Use only new files for creating

                        };

                        mutate(createData);  // Use CreateLeak for creating
                        // console.log("console.log", combinedFiles)
                    }

                    setSubmitting(false);
                }}

            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div>
                            <label className="query-label">Leak Title *</label>
                            <input type="text"
                                className="form-control query-input"
                                name="title"
                                placeholder="Enter leak title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                            ></input>
                        </div>
                        {!myownleak ? (
                            <div className="">
                                <label className="query-label">Post Anonymously *</label>
                                <select
                                    className="form-control query-input"
                                    name="is_anonymous"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.is_anonymous}
                                >
                                    <option value="">Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        ) : (
                            null
                        )
                        }
                        {!myownleak ? (
                            <div>
                                <h4 className="query-label">Tag</h4>
                                <div className='parent-who'>
                                    <div className='row'>
                                        {/* Conditional rendering based on selectedDropdown */}
                                        <div className='col-lg-8'>
                                            {selectedDropdown === 'journalist' && (
                                                <div className='journalist-dropdown'>
                                                    <Select
                                                        className="sign-up-input-textarea"
                                                        options={journalistOptions}
                                                        isMulti
                                                        value={selectedJournalists}
                                                        onInputChange={(query) => setSearchQuery(query)}
                                                        onChange={setSelectedJournalists} // Update selectedJournalists on change
                                                        placeholder="Search and select journalists..."
                                                        noOptionsMessage={() => (searchQuery ? 'No journalists found' : 'Start typing to search')}
                                                    />
                                                </div>
                                            )}

                                            {selectedDropdown === 'media-outlet' && (
                                                <div className='media-outlet-dropdown'>
                                                    <Select
                                                        className="sign-up-input-textarea"
                                                        options={options1}
                                                        isMulti
                                                        value={values.outlets}
                                                        onChange={(value) => setFieldValue("outlets", value)}
                                                    />
                                                </div>
                                            )}

                                            {selectedDropdown === 'category' && (
                                                <div className='category-dropdown'>
                                                    <Select
                                                        className="sign-up-input-textarea"
                                                        options={options2}
                                                        isMulti
                                                        value={values.tags}
                                                        onChange={(value) => setFieldValue("tags", value)}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {/* Control dropdown */}
                                        <div className='col-lg-4'>
                                            <Select
                                                options={controlOptions}
                                                defaultValue={defaultOption} // Set default value based on initial state
                                                onChange={handleControlChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                        }

                        <div>
                            <label className="query-label">Description *</label>
                            <textarea type="text"
                                className="form-control query-input-description"
                                name="description"
                                placeholder="Enter details about your Leak"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                            />
                        </div>
                        {!myownleak ? (
                            <div>
                                <FileUpload
                                    onFilesChange={(files) => handleFilesChange('images', files)}
                                />

                                <DocumentDropzone
                                    onFilesChange={(files) => handleFilesChange('documents', files)}
                                />
                            </div>

                        ) : (
                            <EditLeakDropzone attachments={myownleak.attachments} onFilesChange={handleFilesChangeEdit} />
                        )
                        }
                        <div className='submit-leak-container'>
                            <button disabled={isLoading} className='create-query-btn mt-5'>{myownleak ? 'Edit Leak' : 'Add Leak'}</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default AddLeak;
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const getFileIcon = (extension) => {
    switch (extension) {
        case 'pdf':
            return <i className="fas fa-file-pdf fa-3x" style={{ color: 'red' }}></i>;
        case 'doc':
        case 'docx':
            return <i className="fas fa-file-word fa-3x" style={{ color: 'blue' }}></i>;
        case 'jpg':
        case 'jpeg':
        case 'png':
            return <i className="fas fa-file-image fa-3x" style={{ color: 'green' }}></i>;
        case 'zip':
        case 'rar':
            return <i className="fas fa-file-archive fa-3x" style={{ color: 'orange' }}></i>;
        default:
            return <i className="fas fa-file-alt fa-3x" style={{ color: 'gray' }}></i>;
    }
};

const EditLeakDropzone = ({ attachments, myownleak, onFilesChange }) => {
    const [newFiles, setNewFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,application/pdf,.doc,.docx',
        onDrop: (acceptedFiles) => {
            setNewFiles((prevFiles) =>
                prevFiles.concat(
                    acceptedFiles.map((file) => ({
                        name: file.name,
                        extension: file.name.split('.').pop(),
                        file,
                    }))
                )
            );
        },
    });

    // Effect to send newFiles to the parent whenever newFiles is updated
    useEffect(() => {
        if (onFilesChange) {
            onFilesChange(newFiles); // Pass newFiles to parent via the callback
        }
    }, [newFiles, onFilesChange]);


    return (
        <div className="container mt-5">
            {/* Dropzone */}
            <div
                {...getRootProps()}
                style={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
            >
                <input {...getInputProps()} />
                <p>Drag & drop files here, or click to select files</p>
            </div>

            {/* Conditionally Display Existing Attachments */}
            {attachments && attachments.length > 0 && (
                <>
                    <h3 className="mt-4">Existing Attachments</h3>
                    <div className="row">
                        {attachments.map((url, index) => {
                            const fileName = url.split('/').pop(); // Get file name from the URL
                            const extension = fileName.split('.').pop(); // Get file extension
                            return (
                                <div key={index} className="col-lg-4 col-md-6 mb-3">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <a href={url} target="_blank" rel="noopener noreferrer">
                                                {getFileIcon(extension)}
                                                <p className='file-fix-edit-name'><strong >{fileName.split('.')[0]}</strong></p>
                                                <p><em>{extension.toUpperCase()}</em></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {/* Conditionally Display New Files */}
            {newFiles.length > 0 && (
                <>
                    <h3 className="mt-4">New Files</h3>
                    <div className="row">
                        {newFiles.map((file, index) => (
                            <div key={index} className="col-lg-4 col-md-6 mb-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        {getFileIcon(file.extension)}
                                        <p><strong>{file.name.split('.')[0]}</strong></p>
                                        <p><em>{file.extension.toUpperCase()}</em></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* {console.log("Newly selected files:", newFiles)} Log the new files here */}
                </>
            )}
        </div>
    );
};

export default EditLeakDropzone;

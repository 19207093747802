import { format, parseISO } from "date-fns";
import searchnow from "../assets/search-normal.svg"
import avatar from "../assets/Avatar.svg"
import briefcase from "../assets/briefcase.svg"
import bookmarking from "../assets/bookmark-query.svg"
import bookmarkinged from "../assets/booknownowimg.svg"
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import Swal from "sweetalert2";
// import { BiEditAlt } from "react-icons/bi";
// import { Link } from "react-router-dom";
// import CustomDataTable from "../CustomDataTable";
import { bookmarkedQuery, getAllQueries } from "../api";
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";


const AllQueryTab = () => {

    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1); //


    // const [pageNumber, setPageNumber] = useState(0);
    const resultsPerPage = 10;
    // const pagesVisited = pageNumber * resultsPerPage;

    const reloadPage = () => {
        setInterval(() => {
            window.location.reload();
        }, 1000); // 1000 milliseconds = 1 second
    };


    const { data: queries, refetch } = useQuery({
        queryKey: ["getAllQueries", currentPage],
        queryFn: () => getAllQueries(searchQuery, currentPage),
        // suspense: true,
    });
    // console.log(queries)

    const { mutate } = useMutation(bookmarkedQuery, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            refetch()
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),

    });



    // const { data: searchprofileresult } = useQuery({
    //     queryKey: ["profilesearch"],
    //     queryFn: () => profileSearch(searchQuery),
    //     enabled: false,
    // });

    const handleSearch = () => {
        refetch();
    };


    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
        // console.log("Selected", selected + 1)
    };

    return (
        <>
            <div className="container">
                <div className="custom-query-table">
                    <div className="the-background-palace">
                        <div className="the-background-log">
                            <img src={searchnow} alt="" onClick={handleSearch} />
                            <input src="" className="input-point" placeholder="Search queries by keyword"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </div>
                        {/* <h2 className="my-4">Search Parameters</h2> */}
                        {queries?.data?.data?.map((item, index) => (
                            <div className="query-container-div-main">

                                <div className="color-div" key={index}>
                                    <div className="response-div1">
                                        {item.journalist.avatar ? (<img src={item.journalist.avatar} alt="" className="mr-2" />) : (<img src={avatar} alt="" className="mr-2" />)}

                                    </div>
                                    <div>
                                        <Link className="cursor-pointer" to={`/queries/${item.uuid}`}><h4 className="color-7-text">{item.title}</h4></Link>
                                        <div className="color-div">
                                            <span className="briefcase">
                                                <img src={briefcase} alt="" className="mr-2" />
                                                <span>{item.tags.join(', ')}</span>
                                            </span>
                                        </div>
                                        <div className="posted-main">
                                            <h4 className="posted-main">
                                                Posted: {format(parseISO(item.created_at), "MMM d, yyyy")}
                                                <span className="posted-main-span">Deadline:{format(parseISO(item.deadline), "MMM d, yyyy h:mma")}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                {item?.is_bookmarked ? (
                                    <img
                                        src={bookmarkinged}
                                        className="book-this-mark"
                                        alt=""
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Do you want to Unbookmark this Query?",
                                                showCancelButton: true,
                                                confirmButtonText: "Unbookmark Query",
                                                denyButtonText: `Cancel`,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    mutate(item?.id, {
                                                        onSuccess: () => {
                                                            Swal.fire({
                                                                title: 'Query unbookmarked successfully!',
                                                                icon: 'success',
                                                            }).then(() => {
                                                                // Reload the page or trigger a re-fetch after successful unbookmarking
                                                                window.location.reload();
                                                                // You can use refetch() here if using React Query
                                                            });
                                                        },
                                                        onError: (error) => {
                                                            Swal.fire({
                                                                title: 'Error unbookmarking query!',
                                                                text: error.message,
                                                                icon: 'error',
                                                            });
                                                        },
                                                    });
                                                }
                                            });
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={bookmarking}
                                        className="book-this-mark"
                                        alt=""
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Do you want to bookmark this Query?",
                                                showCancelButton: true,
                                                confirmButtonText: "Bookmark Query",
                                                denyButtonText: `Cancel`,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    mutate(item?.id, {
                                                        onSuccess: () => {
                                                            Swal.fire({
                                                                title: 'Query bookmarked successfully!',
                                                                icon: 'success',
                                                            }).then(() => {
                                                                // Reload the page or trigger a re-fetch after successful bookmarking
                                                                window.location.reload();
                                                                // You can use refetch() here if using React Query
                                                            });
                                                        },
                                                        onError: (error) => {
                                                            Swal.fire({
                                                                title: 'Error bookmarking query!',
                                                                text: error.message,
                                                                icon: 'error',
                                                            });
                                                        },
                                                    });
                                                }
                                            });
                                        }}
                                    />
                                )}

                            </div>
                        ))}
                    </div>

                </div>
                <ReactPaginate
                    previousLabel={'< Prev'}
                    previousClassName="previous-class"
                    nextLabel={'Next >'}
                    nextClassName="next-class"
                    pageCount={Math.ceil(queries?.data?.total / resultsPerPage)}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                // pageClassName={'page-item-class'}
                />
            </div>
        </>
    )
}
export default AllQueryTab;

import logo from "../assets/ConnectedNewsrooms.svg"
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { VerifyOTP, getQuestions } from "../api";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
// import Select from 'react-select'
import { useSearchParams } from "react-router-dom";
// import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useNavigate } from "react-router-dom";

const ConfirmSecurityQuestion = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // const { setUser } = useProtectedRoutesContext();
    const urlToken = searchParams.get('token');

    const signInSchema = yup.object().shape({
        // password_question_id: yup.string().required("Please choose a forgot password Question"),
        // password_question_answer: yup.string().required("Please choose a forgot password Answer"),
        otp: yup.number().required("Please enter OTP")
    });

    const { data: questions } = useQuery({
        queryKey: ["getQuestions"],
        queryFn: () => getQuestions(),
    });
    // console.log("Token", searchParams.get("token"))

    const storedEmail = localStorage.getItem('resetemail');

    // console.log(storedEmail)

    const initialValues = {
        // password_question_id: "",
        // password_question_answer: "",
        // token: searchParams.get("token"),
        otp: "",
        email: storedEmail,
    };

    const { mutate, isLoading } = useMutation(VerifyOTP, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            navigate(`/newpassword`);
            localStorage.setItem('urlToken', urlToken);

        },
        onError: (error) => renderErrorMessage(error),
    });


    const options1 = questions?.data?.map((option, index) => ({
        value: option?.id,
        label: option?.question,
    }));



    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="logo-img" src={logo} alt="" />
            </div>
            <div className="container">
                <div className="sign-up-form">
                    <div className="input-container">
                        <h2 className="sign-up-form-text1">Enter the OTP sent to your email</h2>
                    </div>
                    {/* <p className="sign-up-form-text-sub">Create your account</p> */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        onSubmit={({ password1, ...values }) => mutate(values)}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                {/* <div className="form-group">
                                    <label className="signup-label mt-5">Choose a forgot password question</label>
                                    <Select
                                        className=""
                                        value={options1?.find(option => option?.value === values.password_question_id)}
                                        onChange={(value) => setFieldValue("password_question_id", value.value)}
                                        options={options1}
                                    />
                                    <ErrorMessage
                                        name="password_question_id"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="signup-label">Answer</label>
                                    <input
                                        type="text"
                                        className=" sign-up-input"
                                        name="password_question_answer"
                                        value={values.password_question_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                        name="password_question_answer"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label className="signup-label">Enter OTP</label>
                                    <input type="otp"
                                        className=" sign-up-input"
                                        id="exampleInputEmail1" name="otp"
                                        value={values.otp}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    <ErrorMessage
                                        name="otp"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                                <button type="submit" className=" sign-up-btn" disabled={isLoading}>Submit</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ConfirmSecurityQuestion;

import { useFormikContext } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ProfileImageDropzone = ({ setFieldValue, avatarPerson }) => {
    const { values } = useFormikContext();
    const [newImageSelected, setNewImageSelected] = useState(false);
    const fileInputRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        setNewImageSelected(true);
        setFieldValue('avatar', acceptedFiles[0]);  // Setting the actual file here
        setFieldValue('avatarPreview', URL.createObjectURL(acceptedFiles[0]));  // Setting the preview URL here
    }, [setFieldValue]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false,
    });

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const displayedImage = newImageSelected ? values.avatarPreview : avatarPerson;
    // const displayedImage = newImageSelected && values.avatarPreview ? values.avatarPreview : avatarPerson;

    return (
        <div className="profile-image-dropzone">
            <div {...getRootProps()} className="dropzone-container">
                <input {...getInputProps()} ref={fileInputRef} />
                {displayedImage ? (
                    <img src={displayedImage} alt="Profile" className="profile-image" />
                ) : (
                    <div className="placeholder">Drop image here</div>
                )}
            </div>
            <div className="change-button" onClick={handleClick}>
                Change Photo
            </div>
        </div>
    );
};

export default ProfileImageDropzone;

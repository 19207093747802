import axios from "axios";
import { getFromStorage, renderErrorMessage, renderSuccessMessage } from "../assets/functions";

const authApi = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
    },
    withCredentials: true,
});


authApi.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);




export const ResetEmailAdd = (data) => {
    return api.post("source/reset-password", data);
};


export const signIn = (data) => {
    return api.post("source/login", data);
};
export const signUp = (data) => {
    return api.post("source/register", data);
};
export const getQuestions = () => {
    return api.get("questions");
};

export const InputPasswords = (data) => {
    return api.patch("source/create-profile", data);
};


export const VerifyOTP = (data) => {
    return api.post("source/verify-otp", data);
};

export const NewCredentials = (data) => {
    return api.patch("source/create-new-password", data);
};



const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_LOCAL_BASEURL}`
            : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": true,
    },
    // withCredentials: true,
});


api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
    return config;
}, null);



api.interceptors.response.use(
    (response) => response.data,

    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
            throw new Error(error.response.data.message ?? "Account Created");
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);


export const getAllQueries = (searchQuery, page) => {
    return api.get(`source/queries?q=${searchQuery}&page=${page}`);
};
export const getAllJournalist = (searchQuery) => {
    return api.get(`journalists?q=${searchQuery}`);
};
export const getNotification = (page) => {
    return api.get(`source/notifications?page=${page}`);
};
export const getBookmarkedQueries = (page) => {
    return api.get(`source/bookmarks?page=${page}`);
};
export const bookmarkedQuery = (id) => {
    return api.post(`/source/queries/${id}/bookmark`);
};
export const getQueryDetails = (id, page) => {
    return api.get(`/source/queries/${id}?page=${page}`);
};
export const createPitch = ({ queryId, ...data }) => {
    return api.post(`/source/queries/${queryId}/pitch`, data, { headers: { "Content-Type": "multipart/form-data" } });
};
export const getTags = () => {
    return api.get("tags");
};

export const getAssociatedOutlets = () => {
    return api.get("associated-outlets");
};



export const createAndUpdateProfile = (data) => {
    return api.post("source/update-profile", { _method: "PATCH", ...data }, { headers: { "Content-Type": "multipart/form-data" } });
};

export const profileSearch = (searchParams, role, page) => {
    return api.get(`profile-search?q=${searchParams}&role=${role}&page=${page}`);
};

export const readNotification = (notificationId) => {
    // console.log("notificationId", notificationId)
    return api.patch(`source/notifications/${notificationId}`);

};
export const ReplyPitch = ({ queryId, pitchId, replyText }) => {
    return api.post(`source/queries/${queryId}/pitch/${pitchId}/reply`, { reply: replyText });
};

export const changePassword = (data) => {
    return api.patch("source/change-password", data);
};

export const getUserProfile = (id, role, userRole) => {
    return api.get(`profile-search/${id}?role=${role}&userRole=${userRole}`);
};

export const CreateLeak = (data) => {
    return api.post("source/leaks", data, {
        headers: { "Content-Type": "multipart/form-data" }
    });
};
export const EditALeak = ({ id, ...data }) => {
    return api.post(`source/leaks/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" }
    });
};

export const getMyLeaks = (page) => {
    return api.get(`source/leaks?page=${page}`);
};

export const getMyLeakDetails = (id, page) => {
    return api.get(`source/leaks/${id}?page=${page}`);
};


export const ReplyLeak = ({ leakId, ...data }) => {
    return api.post(`source/leaks/${leakId}/reply`, data);
};
export const PostLeakReply = ({ leakId, ...data }) => {
    return api.post(`source/leaks/${leakId}/reply`, data);
};

export const DeleteLeak = (id) => {
    return api.delete(`source/leaks/${id}`);
};


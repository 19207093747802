import React from "react";

const Loader = () => {
    return (
        <div class="loader-container">
            <div class="loader"></div>
        </div>
    );
};

export default Loader;

import { useMutation } from "@tanstack/react-query";
import { ErrorMessage, Formik } from "formik";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import * as yup from "yup";
import { changePassword } from "../api";
import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";


const ChangePassword = () => {
    const [visible, setVisible] = useState(false);

    const [visible1, setVisible1] = useState(false);

    const [visible2, setVisible2] = useState(false);

    const signInSchema = yup.object().shape({
        new_password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),
        new_password_confirmation: yup
            .string()
            .required("Confirm your password")
            .oneOf([yup.ref("new_password"), null], "Passwords don't match"),
    });

    const initialValues = {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
    };

    const { mutate, isLoading } = useMutation(changePassword, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            // setUser(data?.data.user);
            // setToken(data?.data?.token);
            // navigate(`/queries`);
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),

    });


    return (
        <div>
            <div className="container">
                {/* <p className="my-queries-sub">Change Password</p> */}
                <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    onSubmit={(values) => mutate(values)}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-5">
                                <label className="signup-label">Old Password</label>
                                <div className="has-pwd-icon">
                                    <input
                                        className=" sign-up-input"
                                        name="old_password"
                                        onChange={handleChange}
                                        value={values.old_password}
                                        onBlur={handleBlur}
                                        type={visible ? "text" : "password"}
                                    />
                                    {visible ? (
                                        <BsEye
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <BsEyeSlash
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )}

                                    <ErrorMessage
                                        name="old_password"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="signup-label">New Password</label>
                                <div className="has-pwd-icon">
                                    <input
                                        className=" sign-up-input"
                                        name="new_password"
                                        onChange={handleChange}
                                        value={values.new_password}
                                        onBlur={handleBlur}
                                        type={visible1 ? "text" : "password"}
                                    />
                                    {visible1 ? (
                                        <BsEye
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible1(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <BsEyeSlash
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible1(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <ErrorMessage
                                    name="new_password"
                                    component="div"
                                    className="text-red-500"
                                />
                            </div>
                            <div className="form-group">
                                <label className="signup-label">Confirm Password</label>
                                <div className="has-pwd-icon">
                                    <input
                                        className=" sign-up-input"
                                        name="new_password_confirmation"
                                        onChange={handleChange}
                                        value={values.new_password_confirmation}
                                        onBlur={handleBlur}
                                        type={visible2 ? "text" : "password"}
                                    />
                                    {visible2 ? (
                                        <BsEye
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible2(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <BsEyeSlash
                                            className="view-pwd"
                                            size={20}
                                            onClick={() =>
                                                setVisible2(
                                                    (prevState) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <ErrorMessage
                                    name="new_password_confirmation"
                                    component="div"
                                    className="text-red-500  text-[1.4rem]"
                                />
                            </div>
                            <button type="submit" className=" sign-up-btn btn-primary" disabled={isLoading}>Change Password</button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default ChangePassword;
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFilePdf, FaFileWord, FaFileArchive, FaFileVideo, FaTrash } from 'react-icons/fa';

const DocumentDropzone = ({ onFilesChange }) => {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        const validFiles = acceptedFiles.filter(file => file.size <= 20 * 1024 * 1024); // 20MB limit

        // Handle file rejections for oversized files
        fileRejections.forEach(rejection => {
            if (rejection.file.size > 20 * 1024 * 1024) {
                window.alert(`File ${rejection.file.name} is too large. Maximum size allowed is 20MB.`);
            }
        });

        // Add valid files to the state
        setFiles(prevFiles => {
            const newFiles = [...prevFiles, ...validFiles];
            onFilesChange(newFiles); // Pass files to parent
            return newFiles;
        });
    }, [onFilesChange]);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/zip': [],
            'video/mp4': [],
            'video/mpeg': [],
            'video/quicktime': [],
            'video/x-msvideo': [],
        },
        noClick: true,
        noKeyboard: true,
    });

    const removeFile = (file) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(f => f !== file);
            onFilesChange(updatedFiles); // Pass updated files to parent
            return updatedFiles;
        });
    };

    const renderFileIcon = (file) => {
        const ext = file.name.split('.').pop().toLowerCase();
        switch (ext) {
            case 'pdf':
                return <FaFilePdf size={40} color="#D32F2F" />;
            case 'docx':
                return <FaFileWord size={40} color="#1976D2" />;
            case 'zip':
                return <FaFileArchive size={40} color="#FF8F00" />;
            case 'mp4':
            case 'mpeg':
            case 'mov':
            case 'avi':
                return <FaFileVideo size={40} color="#4CAF50" />;
            default:
                return null;
        }
    };

    return (
        <div className="document-dropzone-container">
            <div {...getRootProps({ className: 'document-dropzone' })}>
                <input {...getInputProps()} />
                <p>Drop your files here or click to upload (ZIP, PDF, DOCX, Video)</p>
                <p>Max File Size is 20MB</p>
                <button type="button" onClick={open} className="upload-button">Select Files</button>
            </div>
            <div className="document-list">
                {files.map((file, index) => (
                    <div key={index} className="document-item">
                        {renderFileIcon(file)}
                        <div className="document-info">
                            <p className="document-name">{file.name}</p>
                            <p className="document-extension">{file.name.split('.').pop().toUpperCase()}</p>
                        </div>
                        <button className="file-remove-btn" onClick={() => removeFile(file)}>
                            <FaTrash />
                        </button>
                    </div>
                ))}
            </div>
            <p className='clear-doubt-text'><a target="_blank" rel="noopener noreferrer" href='https://www.freeconvert.com/video-compressor'>Compress Video</a></p>
        </div>
    );
};

export default DocumentDropzone;




const Footer = () => {
    return (
        <>
            <div className="footer-bcg">
                <div className="container">
                    <div className="footer-div">
                        <h6 className="copy-right-text">Copyright © 2024 Connected Newsrooms | All Rights Reserved </h6>
                        <div className="policy-container">
                            <h6 className="policy-text">Privacy Policy</h6>
                            <h6 className="terms-text">Terms of Service</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Footer;
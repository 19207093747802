import logo from "../assets/ConnectedNewsrooms.svg"
import { BsEye, BsEyeSlash } from "react-icons/bs";
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select'
import { useSearchParams } from "react-router-dom";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import { InputPasswords, getQuestions } from "../api";

const InputPassword = () => {
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);



    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setUser } = useProtectedRoutesContext();



    const signInSchema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),
        password1: yup
            .string()
            .required("Confirm your password")
            .oneOf([yup.ref("password"), null], "Passwords don't match"),
        password_question_id: yup.string().required("Please choose a forgot password Question"),
        password_question_answer: yup.string().required("Please choose a forgot password Answer"),
    });

    const { data: questions } = useQuery({
        queryKey: ["getQuestions"],
        queryFn: () => getQuestions(),
    });



    const initialValues = {
        password: "",
        password1: "",
        password_question_id: "",
        password_question_answer: "",
        token: searchParams.get("token"),
    };

    const { mutate, isLoading } = useMutation(InputPasswords, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setUser(data?.data);
            navigate(`/allset`);
        },
        onError: (error) => renderErrorMessage(error),
    });


    const options1 = questions?.data?.map((option, index) => ({
        value: option?.id,
        label: option?.question,
    }));

    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="" src={logo} alt="" />
            </div>
            <div className="container">
                <div className="sign-up-form">
                    <div className="input-container">
                        <h2 className="sign-up-form-text1">Welcome to Connected Newsrooms</h2>
                    </div>
                    <p className="sign-up-form-text-sub">Create your account</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        onSubmit={({ password1, ...values }) => mutate(values)}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="signup-label">Password</label>
                                    <div className="has-pwd-icon">
                                        <input
                                            name="password"
                                            className=" sign-up-input"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={visible ? "text" : "password"}
                                        />
                                        {visible ? (
                                            <BsEye
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <BsEyeSlash
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <ErrorMessage
                                        name="password1"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="signup-label">Confirm Password</label>
                                    <div className="has-pwd-icon">
                                        <input
                                            name="password1"
                                            className=" sign-up-input"
                                            value={values.password1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={visible1 ? "text" : "password"}
                                        />
                                        {visible1 ? (
                                            <BsEye
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible1(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <BsEyeSlash
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible1(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <ErrorMessage
                                        name="password1"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="signup-label">Choose a forgot password question</label>
                                    <Select
                                        className="the-signs"
                                        value={options1?.find(option => option?.value === values.password_question_id)}
                                        onChange={(value) => setFieldValue("password_question_id", value.value)}
                                        options={options1}
                                    />
                                    <ErrorMessage
                                        name="password_question_id"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="signup-label">Answer</label>
                                    <input
                                        type="text"
                                        className=" sign-up-input"
                                        name="password_question_answer"
                                        value={values.password_question_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                        name="password_question_answer"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                                <button type="submit" className=" sign-up-btn" disabled={isLoading}>Create my Account</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default InputPassword;

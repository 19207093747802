import { Link } from "react-router-dom";
import logo from "../assets/ConnectedNewsrooms.svg"

const AllSet = () => {
    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="" src={logo} alt="" />
            </div>
            <h1 className="verify-heading">You’re all set</h1>
            <p className="verify-heading-sub">Congratulations on completing your onboarding</p>
            <div className="save-and-continue-contain">
                <Link to={"/login"}><button className="save-and-continue">Take me to the App</button></Link>
            </div>
        </div>
    )

}

export default AllSet;
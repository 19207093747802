import Footer from "../footer/Footer";
import Navbar from "../nav/NavBar";
import { Helmet } from "react-helmet";

const Landing = () => {
    return (
        <div>
            <Helmet>
                <title>Connected Newsroom | We Connect journalists to Experts</title>
            </Helmet>
            <Navbar />
            <div className="main-background">
                <div className="container">
                    <div className="unique-container">
                        <h1 className="a-unique-source">A Unique source for Every Story</h1>
                        <h6 className="we-connect"> We connect journalists seeking expertise to include in their content with sources who have that expertise.</h6>
                    </div>
                    <div className="im-btn-div">
                        <button className="im-journalist-btn"><a href="https://journalist.yourconnectednewsroom.com/login">I'm a Journalist</a></button>
                        <button className="im-source-btn"><a href="https://yourconnectednewsroom.com/login">I'm a Source</a></button>
                    </div>

                </div>
            </div>
            <div className="container">
                <h4 className="our-process">Our process</h4>
                <div className="our-process-sub">
                    <h6>A Social Impact Platform that’s Good for All</h6>
                    <p> We connect journalists seeking expertise to include in their content with sources who have that expertise.</p>
                </div>
            </div>
            <Footer />
        </div>

    )

}

export default Landing;
import { Outlet } from "react-router-dom"
import { Link } from "react-router-dom";
import logo from "../assets/ConnectedNewsrooms.svg"
import search from "../assets/search-normal.svg"
// import notification from "../assets/notification-bing.svg"
// import ladyperson from "../assets/person-lady.svg"
// import ladyprofile from "../assets/dropdown-profile.svg"
// import profileperson from "../assets/profile.svg"
// import profilenotification from "../assets/notification-bing1.svg"
// import bookmark1 from "../assets/bookmark1.svg"
// import settings from "../assets/setting-2.svg"
// import cnlogo from "../assets/CN.svg"
// import logout from "../assets/logout.svg"



const Navbar = () => {
    return (
        <>
            <div className="">
                <nav className="navbar navbar-bcg navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand"><img src={logo} alt="" className="profile-logo1" /></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="https://journalist.yourconnectednewsroom.com/login">Journalists<span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://yourconnectednewsroom.com/login">Sources</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="google.com">Anonymous Submission</a>
                                </li> */}
                            </ul>
                            <form className="form-inline my-2 my-lg-0">
                                <div className="search-div">
                                    <img src={search} alt="" />
                                    <input className="search-input" placeholder="Search" />
                                </div>
                                <button className="search-btn-signup"><a href="https://yourconnectednewsroom.com/register">Sign Up</a></button>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )

}

export default Navbar;
import React, { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import addicon from "../assets/add.svg";

const FileUpload = ({ onFilesChange }) => {
    const [files, setFiles] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null); // State to track the selected thumbnail
    const fileInputRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        const updatedFiles = [...files, ...acceptedFiles];
        setFiles(updatedFiles);
        setSelectedIndex(null); // Reset selection when new files are added
        onFilesChange(updatedFiles); // Pass files to parent
    }, [files, onFilesChange]);

    const removeFile = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        onFilesChange(updatedFiles); // Pass updated files to parent
        if (selectedIndex === index) {
            setSelectedIndex(null); // Deselect if the selected file is removed
        }
    };

    const handleThumbnailClick = (index) => {
        setSelectedIndex(index); // Set the clicked thumbnail as selected
    };

    const handleAddMoreClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: 'image/*'
    });

    return (
        <div className="custom-dropzone-container">
            <div
                {...getRootProps({
                    className: 'custom-dropzone'
                })}
            >
                <input
                    {...getInputProps()}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                {files.length === 0 ? (
                    <div>
                        <p className='drop-files-text'>Drop your files or click to upload</p>
                        <p className='drop-files-subtext'>Supported file types: PNG, JPG, GIF (Only images are accepted)</p>
                    </div>
                ) : (
                    <div className="preview-image">
                        <img src={URL.createObjectURL(files[0])} alt="Preview" />
                        <button className="remove-btn" onClick={() => removeFile(0)}>
                            &times;
                        </button>
                    </div>
                )}
            </div>
            <div className='clear-doubt'>
                {/* <p className='clear-doubt-text'>Maximum file size: 50MB</p> */}
            </div>
            <div className="thumbnail-container">
                {files.slice(1).map((file, index) => (
                    <div
                        key={index + 1}
                        className={`thumbnail ${selectedIndex === index + 1 ? 'selected' : ''}`}
                        onClick={() => handleThumbnailClick(index + 1)}
                    >
                        <img src={URL.createObjectURL(file)} alt={`Thumbnail ${index + 1}`} />
                        <button className="remove-btn" onClick={() => removeFile(index + 1)}>
                            &times;
                        </button>
                    </div>
                ))}
                {files.length > 0 && (
                    <div className="add-more" onClick={handleAddMoreClick}>
                        <p><img src={addicon} alt="Add more" /></p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileUpload;

import { Navigate, Outlet } from "react-router-dom";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
// import Navbar from "../nav/NavBar";

const MainLayout = () => {
    const { user, token } = useProtectedRoutesContext();

    if (!user || !token) return <Navigate to="/login" />;

    return (
        <div className="main">

            <Outlet />
        </div>
    );
};

export default MainLayout;

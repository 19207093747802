import logo from "../assets/ConnectedNewsrooms.svg"
import { useMutation } from "@tanstack/react-query";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { signIn } from "../api";
import { Helmet } from "react-helmet";

const SignIn = () => {
    const { setUser, setToken } = useProtectedRoutesContext();
    const navigate = useNavigate();

    const signInSchema = yup.object().shape({
        email: yup
            .string()
            .email("Please provide a valid email")
            .required("Please provide a valid email"),
        password: yup
            .string()
            .required("Please provide password")
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),
    });

    const initialValues = {
        email: "",
        password: "",
    };
    const { mutate, isLoading } = useMutation(signIn, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setUser(data?.data.user);
            setToken(data?.data?.token);
            navigate(`/queries`);
            // }
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),

    });


    return (
        <div>
            <Helmet>
                <title>Connected Newsroom | SignIn</title>
            </Helmet>
            <div className="main-background1">
                <div className="signup-container">
                    <img className="logo-img" src={logo} alt="" />
                </div>
                <div className="container">
                    <div className="sign-up-form">
                        <h2 className="sign-up-form-text">Sign In</h2>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={signInSchema}
                            onSubmit={(values) => mutate(values)}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="signup-label">Email</label>
                                        <input type="email" className=" sign-up-input"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />

                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-red-500  text-[1.4rem]"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="signup-label">Password</label>
                                        <input type="password" className=" sign-up-input"
                                            name="password"
                                            onChange={handleChange}
                                            value={values.password}
                                            onBlur={handleBlur} />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="text-red-500  text-[1.4rem]"
                                        />
                                    </div>
                                    <div className="reset-password">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="signup-label" for="exampleCheck1">Remember Me</label>
                                        </div>
                                        <Link to={"resetemail"}><h5 className="signup-label">Reset Password</h5></Link>
                                    </div>

                                    <button type="submit" className="sign-up-btn" disabled={isLoading}>Sign In</button>
                                    <p className="sign-up-form-text-sub-small">Don't have an account?<a className="sign-up-form-text-sub-small-login" href="/register"> Sign Up</a></p>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SignIn;